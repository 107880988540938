import React, {useEffect, useState} from "react";
import coat from "assets/images/coat_kenya.png";
import huduma from "assets/images/huduma.png";
import card_icon from "assets/images/card_icon_back.svg";
import {Modal} from 'antd';
import ReactGA from 'react-ga';
import {Layout, Button} from 'antd';
import {Input} from 'antd';
import {getCardStatus} from "../services/apiSrv";
import PickUpDetailsUID from "./PickUpDetailsUID";
import { Tag, Divider } from 'antd';
const { Search } = Input;

const {Header, Footer, Sider, Content} = Layout;


const Main = () => {
    const trackingId = "UA-184237376-1"; // Replace with your Google Analytics tracking ID
    ReactGA.initialize(trackingId);
    let Uid = (new URLSearchParams(window.location.search)).get("UID")
    ReactGA.set({
        // any data that is relevant to the user session
        // that you would like to track with google analytics
    })
    const [loading, setLoading] = useState(false)
    const [value, setValue] = useState(0)
    const [length, setLength] = useState(9)
    const [idNumber, setIdNumber] = useState("")
    const [status, setStatus] = useState("")
    const [printingStatus, setPrintingStatus] = useState("")
    const [deliveryPoint, setDeliveryPoint] = useState("")
    const [collectionStatus, setCollectionStatus] = useState("")
    const [message, setMessage] = useState("")
    const [response, setResponse] = useState( {})



    const [isModalVisible, setIsModalVisible] = useState(false);
    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };
    const onSearch = value => {

        setValue(value)
        console.log("value is ", value)
        setLoading(true)

        if (value.length >= 6 && value.length <= 9) {
            getCardStatus(value).then(resp => {
                    console.log(resp);
                    setResponse(resp);
                    showModal();
                    setLoading(false);
                }
            ).catch(error => {
                console.log("error is", error)
                setMessage("invalid request")
                setIdNumber(value)
                setLoading(false)
            })

        } else {

            setResponse({
                "id_number": "XXXXX",
                "status": "failed",
                "printing_status": null,
                "collection_status": null,
                "delivery_point": null,
                "message": "Invalid Id Number entered. Please confirm and retry."
            })
            setLoading(false)
            showModal();

        }
        }

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    return (

        <Layout>
            <div>


            <Modal title="Card status" visible={isModalVisible}
                   onCancel={handleCancel}
                   footer={[
                       <Button key="submit" type="primary"  block loading={loading} onClick={handleOk}>
                           Close
                       </Button>,

                   ]}>
                <Content className="p-5"><img align="center" src={card_icon} alt="card icon"
                                              className="main-img m-auto"/></Content>

                <p className="id center_success">Id number | {response.id_number}</p>
                {response.status === "success" &&
                < div >
                    <Divider orientation="center">Printing status</Divider>
                    <div className="center_success">
                        <Tag color="success" size="large"  >{response.printing_status}</Tag>
                    </div>
                    <Divider orientation="center">Collection status</Divider>
                    <div className="center_success">
                        <Tag color="processing">{response.collection_status}</Tag>
                    </div>
                    <Divider orientation="center">Delivery Point</Divider>
                    <div className="center_success">
                        <h5 className="success_status" align="center"> {response.delivery_point}</h5>
                    </div>
                < /div>
                }
                {response.status === "failed" &&
                    <div>
                    <Divider orientation="center">Message</Divider>
                    <div className="center_success">
                    <h5 className="success_status" align="center"> {response.message}</h5>
                    </div>
                    </div>
                }


            </Modal>
            {Uid &&  <PickUpDetailsUID Uid={Uid}  />
            }{ Uid==null &&
                <div>

            <Content>
                <div className="animate mb-5">
                    <div className="container ">
                        <div className="row">
                            <div className="col-md-8 main-div">
                                <div className="header-image">
                                    <img src={coat} alt="" className="main-img"/>
                                </div>
                                <h2 className="main-title">
                                    Get your Huduma Card registration status and delivery address
                                    confirmation.
                                </h2>
                                <p className="main-subtitle text-secondary">
                                    The purpose of this initiative is to create and manage a central
                                    master population database which will be the 'single source of
                                    truth' on a person's identity.
                                </p>


                                <div className="pt-4 ">
                                    <Search
                                        className="pa-5"
                                        placeholder="Enter Id number"
                                        enterButton="Check card status"
                                        size="large"
                                        loading={loading}
                                        type="number"
                                        required
                                        onSearch={onSearch}

                                    />
                                </div>

                            </div>
                            <div className="col-md-4 d-none d-md-flex justify-content-center align-items-center">
                                <img
                                    src={huduma}
                                    alt=""
                                    style={{width: "250px", height: "120px"}}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Content>
                <div className="foot">Copyright © 2021 | All Rights Reserved | Ministry of Interior and Coordination of
                National Government</div>
                </div>
            }


            </div>
        </Layout>
    );
};

export default Main;
