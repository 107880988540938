import React from 'react'
import { Button ,Alert} from 'antd';
import { useHistory } from "react-router-dom";


const ButtonStepper = (props) => {

const {loading,errorMessage,seterrorMessage,setSuccessMessage,successMessage}=props

let history = useHistory();


const onClose = (e) => {

seterrorMessage(null);
}




const onCloseSuccess = (e) => {
  
  setSuccessMessage(null);
  
  history.push("/");
  window.location.reload();
};

  // Perform all logics here
  return (
    <div>
      {errorMessage && 
       <Alert
      message="Error"
      className="mb-2"
      description={errorMessage}
      type="error"
      showIcon
      closable
      onClose={onClose}
    />
    }

  

{successMessage && 
    <Alert message="Success"    className="mb-2" description={successMessage} type="success" showIcon closable  onClose={onCloseSuccess} />

    }


     <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          style={{ background: "#dc3545", borderColor: "#dc3545" }}
        block
        >
          Next
        </Button>

    </div>
  );
};

export default ButtonStepper;
