import React from 'react'
import OTP from './Otp'

const OtpWrapper = () => {
    // Perform all logics here
    return (
        <div>
           <OTP/> 
        </div>
    )
}

export default OtpWrapper
