import React from 'react'
import VerifyPhone from './VerifyPhone'


const VerifyPhoneWrapper = () => {
    // Perform all logics here
    return (
        <div>
           <VerifyPhone/> 
         
        </div>
    )
}

export default VerifyPhoneWrapper
