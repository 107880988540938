import React from 'react'
import Details from './PickupDetails'

const  PickupDetailsWrapper = () => {
// Perform all logics here

    return (
        <div>
           <Details />
        </div>
    )
}

export default PickupDetailsWrapper
