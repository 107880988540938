import React, { useEffect,useState,useContext } from 'react'
import Api_UID from 'components/api/Api_UID'
import PickupDetails from 'components/Stepper/pickUp/PickupDetails'
import { Card } from 'antd';
import { useHistory } from 'react-router';
import { Context } from 'context/Context';
import Header from 'components/header/Header'
import Api from "../components/api/Api";

const PickUpDetailsUID = (props) => {
    const {Uid}=props
    const { Meta } = Card;

    const { next,setRegistrationNumber } = useContext(Context);

    const [response, setResponse] = useState(null)
    let history = useHistory();

    const [loading, setloading] = useState(true)

    useEffect(() => {
        requestContext();
    }, [])

    const requestContext = () => {

        let config = {
            headers: {
                Accept: "application/json;charset=UTF-8",
              },
        };
        Api_UID.get(
          "/context/"+Uid,
          config
        ).then(
          (response) => {
            console.log(response)
            setloading(false)
            if(response.status===200){
                //display name and gender

                setResponse(response.data)
                setRegistrationNumber(response.data.registration_no)

            }else{
                 history.push({
    pathname: "/",

});
              console.log(response.data.description)
            }



          },
          (error) => {
            setloading(false)
            history.push({
    pathname: "/",

});

          }
        );
      };

      function getGender(val){
          if (val==="F")
          return "Female"
          else return "Male"
      }

    return (
        <div>

<div className="content">
    <div className="container">
      <Header />
      <div className="card col-md-8 offset-md-2 p-xs-0 p-sm-4">
        <div className="card-body">

                <Card type="inner" title="Bio Data">


                {response&&  <Meta

            title={response.full_name}
            description={getGender(response.gender)}
          />}

                </Card>
                <Card
                style={{ marginTop: 16 }}
                type="inner"
                title="Pick up address"

                >
                     {response&&
                <PickupDetails/>}
                </Card>



        </div>
      </div>

    </div>

    </div>

        </div>
    )
}

export default PickUpDetailsUID
