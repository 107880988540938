import React, { useContext,useState } from "react";
import { Context } from "context/Context";
import Api from "components/api/Api";
import ButtonStepper from "../ButtonStepper";
import "../index.css"
const CaptureDetails = () => {

  const { next,setRegistrationNumber } = useContext(Context);

  const [firstName, setfirstName] = useState("")

  const [nationalIdnumber, setnationalIdnumber] = useState("")

  const [yearOfBirth, setyearOfBirth] = useState("")

  const [email, setemail] = useState("")

  const [errorMessage, seterrorMessage] = useState(null)


  const [loading, setLoading] = useState(false)
    //do validation
    const handleOnchange = (evt) => {
      evt.preventDefault();
      switch (evt.target.name) {
        case "firstName":
          return setfirstName(evt.target.value);

        case "idNumber":
          return setnationalIdnumber(evt.target.value);

        case "yearBirth":
            return setyearOfBirth(evt.target.value);

        case "email":
              return setemail(evt.target.value);

        default:
          return "required";
      }
    };





  // const requestHudumaStatus = (evt) => {
  //   evt.preventDefault();
  //
  //   setLoading(true)
  //   let config = {
  //     headers: {
  //       "Content-Type": "application/json;charset=UTF-8",
  //       Accept: "application/json;charset=UTF-8",
  //     },
  //   };
  //
  //   Api.post(
  //     "/requestHudumaStatus",
  //     {
  //       phoneNumber: "254713197277",
  //         idNumber:nationalIdnumber,
  //         firstname:firstName,
  //         yearOfBirth:yearOfBirth,
  //         email:email
  //
  //     },
  //     config
  //   ).then(
  //     (response) => {
  //
  //
  //       if(response.data.status==="OK"){
  //
  //         setRegistrationNumber(response.data.registrationNumber)
  //         next()
  //       }else{
  //         seterrorMessage(response.data.description)
  //         console.log(response.data.description)
  //
  //       }
  //
  //       setLoading(false);
  //
  //
  //     },
  //     (error) => {
  //      setLoading(false);
  //       console.log(error);
  //     }
  //   );
  // };

  return (
    <form id="form"  >

      <div className="card-body">
        <h6 className="py-3">Enter Details</h6>

        <div className="form-group">
          <label htmlFor="firstName" className="label">
            First name as in the ID
          </label>
          <input
            type="text"
            className="form-control"
            name="firstName"
            id="firstName"
            onChange={handleOnchange}
            placeholder="e.g John"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="id_number" className="label">
            National ID Number
          </label>
          <input
            type="text"
            className="form-control"
            name="idNumber"
            maxLength="9"
            id="idNumber"
            onChange={handleOnchange}
            placeholder="e.g 12345678"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="email" className="label">
            Year of birth
          </label>
          <input
            type="number"
            name="yearBirth"
            className="form-control"
            id="yearBirth"
            max="2002"
            maxlength="4"
            min="1900"
            onChange={handleOnchange}
            placeholder="e.g 1990"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="id_number" className="label">
            Email (Optional)
          </label>
          <input
            type="email"
            name="email"
            className="form-control"
            id="id_number"

            onChange={handleOnchange}
            placeholder="e.g email@mail.com"
          />
        </div>
        <ButtonStepper loading={loading}  errorMessage={errorMessage} seterrorMessage={seterrorMessage} />

      </div>
    </form>
  );
};

export default CaptureDetails;
