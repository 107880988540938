import { handle_api_error } from "./errorHandler";
import Api_OTP from "../components/api/Api_OTP";


//  Get functions only
function  getCardStatus (id_number) {

  return new Promise(async function (resolve, reject) {
    try {


      let body={
        "id_number":id_number
      }

      let config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      let endpoint = "card/status";

      let response = await post_api(endpoint,body,config);
      return resolve(response);
    } catch (err) {
      return reject(err);
    }
  });
}

// base functions

async function post_api(endpoint,body, config) {
  return new Promise(async function (resolve, reject) {
    Api_OTP.post( endpoint,body, config)
      .then(function (response) {

        //6. Change the response.data to relevant path to response
        return resolve(response.data);
      })
      .catch(function (error) {
        // 7. Change error.response to exact error response from api
        if (error.response) {

          //8. change error status code to relevant code when server returns error
          if (error.response.status === 500) {
            return reject(handle_api_error(error.response.data));
          }
        } else {
          return reject(error);
        }
      });
  });
}

async function get_api(config) {
  return new Promise(async function (resolve, reject) {
    Api_OTP.post( config)
      .then(function (response) {

        //9. Change the response.data to relevant path to response
        return resolve(response.data);
      })
      .catch(function (error) {
        // 10. Change error.response to exact error response from api
        if (error.response) {

          //11. change error status code to relevant code when server returns error
          if (error.response.status === 500) {
            return reject(handle_api_error(error.response.data));
          }
        } else {
          return reject(error);
        }
      });
  });
}



export {
  getCardStatus
};
