import React, { useContext,useState,useEffect } from "react";
import { Context } from "context/Context";
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import Api_OTP from "components/api/Api_OTP";
import ButtonStepper from "../ButtonStepper";


const VerifyPhone = () => {
  const { next ,setPhoneNumberC,setRegistrationNumber} = useContext(Context);
  

 

  const [phoneNumber, setPhoneNumber] = useState("")

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setPhoneNumberC("")
    setRegistrationNumber("")
  }, [])


  const requestOneTimePin = (evt) => {
    evt.preventDefault();

    if(phoneNumber!=="")setPhoneNumberC(phoneNumber)

    setLoading("true")
    let config = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json;charset=UTF-8",
      },
    };
    Api_OTP.post(
      "/requestOTP",
      {
        phoneNumber: phoneNumber,

      },
      config
    ).then(
      (response) => {
        console.log("what is" ,response,phoneNumber)

        if(response.data.status==="OK"){
          next()
        }else{
          console.log(response.data.description)
        }
       
        setLoading(false);

     
      },
      (error) => {
       setLoading(false);
        console.log(error);
      }
    );
  };

  

  // }
  return (
    <form id="form clearfix" className="mt-3" onSubmit={requestOneTimePin}>
      <h6 className=" py-3">
        Enter your phone number.
      </h6>
      <div className="form-group mb-5">
        <label htmlFor="phone" className="label">
          Phone number
        </label>
      
            <PhoneInput
            required
            name="phone"
      defaultCountry="KE"
      maxlength="11"
      placeholder="Enter phone number e.g 07.."
      value={phoneNumber}
      onChange={(value)=>setPhoneNumber(value)}/>
      </div>


      <ButtonStepper loading={loading} />

    </form>
  );
};

export default VerifyPhone;
