import React, { useContext,useState } from "react";
import { Context } from "context/Context";
import OtpInput from 'react-otp-input';
import Api_OTP from "components/api/Api_OTP";
import ButtonStepper from "../ButtonStepper";

const Otp = () => {
  const { next,phoneNumberC } = useContext(Context);

  const [otp, setotp] = useState("")

  const [loading, setLoading] = useState(false)

  const [errorMessage, seterrorMessage] = useState("")

    const validateOtp = (evt) => {
      evt.preventDefault();

      setLoading(true)
    let config = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json;charset=UTF-8",
      },
    };

    Api_OTP.post(
      "/validateOTP",
      {

        phoneNumber:phoneNumberC,
    otp

      },
      config
    ).then(
      (response) => {
        setLoading(false);

        if(response.data.status==="OK"){
          next()
        }else{

          seterrorMessage(response.data.description)
        }


      },
      (error) => {
        setLoading(false);
        seterrorMessage("Please check your internet connection.")
      }
    );
  };



  return (
    <form id="form"onSubmit={validateOtp} >
      <div className="card-body">
        <h6 className="py-3">Verify your phone number</h6>

        <small>
          A one time password has been sent to your phone number ending with ***{phoneNumberC.slice(phoneNumberC.length - 5)}
        </small>
        <div className="form-group mt-3 mb-2">
          <label htmlFor="otp" className="label">
            One Time Password
          </label>
          <OtpInput
          value={otp}
          isInputNum	="true"
          inputStyle="otp-input text-dark"
          onChange={(v)=>setotp(v)}
          numInputs={6}
          separator={<span>	&nbsp;&nbsp;&nbsp;</span>}
      />
        </div>

      <ButtonStepper  loading={loading} errorMessage={errorMessage}  seterrorMessage={seterrorMessage}/>

      </div>
    </form>
  );
};

export default Otp;
