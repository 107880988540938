import React, { useEffect } from "react";
import Stepper from "components/Stepper/Stepper";
import Main from "pages/Main";
import routes from "./routes";
import { Route, Switch, useHistory } from "react-router-dom";
import PublicLayout from "shared/layout/PublicLayout";
import ReactGA from "react-ga"; //Google Analytics

const pages = [
  {
    exact: true,
    path: routes.home,
    component: Main,
    layout: PublicLayout,
  },
  {
    exact: true,
    path: routes.details,
    component: Stepper,
    layout: PublicLayout,
  },
];

function App() {
  const history = useHistory();
  useEffect(() => {
    ReactGA.initialize("UA-184237376-1");
    ReactGA.pageview(window.location.pathname);
  });

  return (
    <div className="app ">
      <Switch>
        {pages.map(
          ({ exact, path, component: Component, layout: Layout }, index) => (
            <Route
              key={index}
              exact={exact}
              path={path}
              render={(props) => (
                <Layout history={history}>
                  <Component {...props} />
                </Layout>
              )}
            />
          )
        )}
        <Route component={Main} />
      </Switch>
    </div>
  );
}

export default App;
