import React, { useContext, useState } from "react";
import { Context } from "context/Context";
import ButtonStepper from "../ButtonStepper";
import { Modal, Alert } from "antd";
import { useHistory } from "react-router-dom";
import Api, {hudumaApi} from "../../api/Api";
import Api_UID, {apiUid} from "../../api/Api_UID";

var counties = require("../../../counties.json");

const PickupDetails = () => {
  const history = useHistory();

  const {  registrationNumber } = useContext(Context);

  const [county, setcounty] = useState("");
  const [subCounty, setsubCounty] = useState("");
  const [collectionPoint, setcollectionPoint] = useState("");

  const [selectedSubcounties, setselectedSubcounties] = useState([]);
  const [selectedCollectionPoints, setSelectedCollectionPoints] = useState([]);

  const [loading, setLoading] = useState(false);

  const [errorMessage, seterrorMessage] = useState(null);
  const [successMessage, setsuccessMessage] = useState(null);

  //do validation
  const handleOnchangeCounty = (evt) => {
    evt.preventDefault();

    if (evt.target.value === "" && evt.target.name === "county") {
      setselectedSubcounties([]);
      setSelectedCollectionPoints([]);
    }
    if (evt.target.value === "" && evt.target.name === "sub_county") {
      setcollectionPoint([]);
    }

    if (evt.target.value !== "") {
      switch (evt.target.name) {
        case "county":
          if (evt.target.options[evt.target.selectedIndex].id !== null) {
            setSelectedCollectionPoints([]);
            setselectedSubcounties([]);
            setcounty(evt.target.value);
            setsubCounty("");
            setselectedSubcounties(
              counties.counties[evt.target.options[evt.target.selectedIndex].id]
                .payload.sub_county
            );
          }
          break;
        case "sub_county":
          setSelectedCollectionPoints([]);

          setsubCounty(evt.target.value);
          setcollectionPoint("");
          setSelectedCollectionPoints(
            selectedSubcounties[evt.target.options[evt.target.selectedIndex].id].payload.registration_offices
          );
          break;

        case "collection_point":

          setcollectionPoint(evt.target.value);
          break;

        default:
          return "required";
      }
    }
  };

  const submitPickUpDelivery = (evt) => {
    evt.preventDefault();

    setLoading(true);

    const address = collectionPoint;

    const config = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json;charset=UTF-8",
      },
    };

    Api.post(
      "/updateDeliveryAddress",
      {
        address: address,
        registrationNumber: registrationNumber,
      },
      config
    ).then(
      (response) => {
        setLoading(false);
        if (response.data.status === "OK") {
          Modal.success({
            content: (
              <Alert
                message={(
                  response.data.description +
                  "at " +
                  collectionPoint.split("-")[2]
                ).replace(".", " ")}
                type="success"
                showIcon
              />
            ),
            onOk() {
              history.push("/");
              window.location.reload();
            },
          });
          // setsuccessMessage(response.data.description)
        } else {
          seterrorMessage(response.data.description);
        }
      },
      (error) => {
        setLoading(false);
        seterrorMessage("Check your internet connection");
        console.log(error);
      }
    );
  };

  return (
    <form id="form" onSubmit={submitPickUpDelivery}>
      <h6 className="py-3">Select your preffered pickup point.</h6>

      <div className="form-group">
        <label htmlFor="county" className="label">
          County
        </label>
        <select
          className="form-control"
          name="county"
          value={county}
          onChange={handleOnchangeCounty}
          required
        >
          <option value="">Choose your county</option>

          {counties.counties
            .sort((a, b) =>
              a.payload.county
                  .toLowerCase()
                  .localeCompare(b.payload.county.toLowerCase())
            )
            .map((item, key) => (
              <option key={key} id={key} value={item.payload.code}>
                {item.payload.county}
              </option>
            ))}
        </select>
      </div>
      {selectedSubcounties && selectedSubcounties.length > 0 && (
        <div className="form-group">
          <label htmlFor="sub_county" className="label">
            Sub County
          </label>
          <select
            className="form-control"
            id="county"
            name="sub_county"
            value={subCounty}
            onChange={handleOnchangeCounty}
            required
          >
            <option value="" >Choose sub county</option>


            {selectedSubcounties
              .sort((a, b) =>

                a.payload.name
                  .toLowerCase()
                  .localeCompare(b.payload.name.toLowerCase())

              )
              .map((item, key) => (
                <option key={key} id={key} value={item.payload.code}>
                  {item.payload.name.toUpperCase()}
                </option>
              ))}
          </select>
        </div>
      )}
      {selectedCollectionPoints && selectedCollectionPoints.length > 0 && (
        <div className="form-group">
          <label htmlFor="collection_point" className="label">
            Collection Point
          </label>
          <select
            className="form-control"
            id="county"
            value={collectionPoint}
            name="collection_point"
            onChange={handleOnchangeCounty}
            required
          >
            <option value="" disabled  >
              Choose collection point
            </option>

            {selectedCollectionPoints
              .sort((a, b) =>
                a.name.toLowerCase().localeCompare(b.name.toLowerCase())
              )
              .map((collection_point, key) => (
                <option key={key} id={key} value={collection_point.name}>
                  {collection_point.name.split("-")[2]}
                </option>
              ))}
          </select>
        </div>
      )}

      <ButtonStepper
        loading={loading}
        errorMessage={errorMessage}
        successMessage={successMessage}
        setSuccessMessage={setsuccessMessage}
        seterrorMessage={seterrorMessage}
      />
    </form>
  );
};

export default PickupDetails;
